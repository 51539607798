import React from "react";
import HBlog from "./HBlog";
import Search from "../common/search/Search";
import HCategories from "./HCategories";
import HProduct from "./HProduct";

export const Home = () => {
  return (
    <>
      <Search />
      <HCategories />
      <HProduct />
      {/* <HBlog /> */}
    </>
  );
};
