import React, { useEffect, useState } from "react";
import PageHeader from "../common/header/title/PageHeader";
import "./product.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useCart } from "react-use-cart";
import Swal from "sweetalert2";
import Rating from "../common/rating/Rating";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Loader from "../common/loader/Loader";

const SingleProduct = () => {
  // GET SINGLE PRODUCT
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [reviews, setReviews] = useState([]);
  const [laoding, setLoading] = useState(false);
  useEffect(() => {
    const fatchProduct = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVER + `/api/admin/products/${id}`
      );
      setProduct(data);
      setReviews(data.reviews?.reverse());
      setLoading(true);
    };
    fatchProduct();
  }, [product, id]);

  // PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = reviews?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviews?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reviews?.length;
    setItemOffset(newOffset);
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // GET RECOMMENDED PRODUCTS
  const [recomProducts, setRecomProducts] = useState([]);
  useEffect(() => {
    const fatchRecomProduct = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVER + `/api/admin/products/recommended`
      );
      setRecomProducts(data);
    };
    fatchRecomProduct();
  }, [recomProducts]);

  // ADD-TO-CART
  const { addItem } = useCart();
  const addItemHandlar = (item, id) => {
    item.id = id;
    addItem(item);
    Swal.fire({
      icon: "success",
      title: item.title + " Added.",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  return (
    <>
      <PageHeader title={product.title} />
      <section className="product single-product">
        {laoding ? (
          <>
            <div className="container">
              <div className="single-product-item grid-2">
                <div className="left">
                  <img
                    src={product.thumb}
                    alt={product.title}
                  />
                </div>
                <div className="right">
                  <h3>{product.title}</h3>
                  <p>{product.description}</p>
                  <div className="single-order-form">
                    <ul>
                      <li>
                        <span>Price</span>
                        <h4>$ {product.price}</h4>
                      </li>
                      <li>
                        <span>Category</span>
                        <h4>{product.category}</h4>
                      </li>

                      <li>
                        <span>Reviews</span>
                        <h4>
                          <Rating rating={product.rating} />
                          <span>({product.totalReviews})</span>
                        </h4>
                      </li>

                      <li>
                        <span>Status</span>
                        <h4>
                          {product.active === "on" ? "Available" : "Unavailable"}
                        </h4>
                      </li>

                      <li>
                        {product.active === "on" ? (
                          <Link
                            className="btn-primary"
                            onClick={() => {
                              addItemHandlar(product, product.id);
                            }}
                          >
                            <i className="fas fa-shopping-cart"></i> Add To Cart
                          </Link>
                        ) : (
                          <Link className="btn-primary disableLink">
                            <i className="fas fa-shopping-cart"></i> Out Of
                            Stock
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="single-product-item">
                <div className="all-review">
                  <h3 className="text-center" style={{ marginBottom: "20px" }}>
                    REVIEWS
                  </h3>
                  {/* <div className="grid-4">
                    {(reviews && reviews.length === 0) ? (
                      <div className="review-item">
                        <p>No feedback has been given yet.</p>
                      </div>
                    ) : (
                      currentItems.map((item, index) => (
                        <div key={index} className="review-item">
                          <div className="grid-2">
                            <h5 className="name bold">{item.name}</h5>
                            <Rating rating={item.rating} />
                          </div>
                          <p className="date">
                            {item.date && moment(item.date).format("lll")}
                          </p>
                          <p className="content">
                            {item.comment
                              ? item.comment
                              : "No comment given..."}
                          </p>
                        </div>
                      ))
                    )}
                  </div>
                  {reviews.length >= 13 && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">>"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel="<<"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                    />
                  )} */}
                </div>
              </div>
            </div>
            <div className="single-product-item container">
              <h3 className="text-center" style={{ marginBottom: "20px" }}>
                RECOMMENDED PRODUCTS
              </h3>
              <div className="grid-4">
                {recomProducts.slice(0, 4).map((item, index) => (
                  <div key={index} className="items shadow">
                    <div className="img">
                      <img
                        src={
                           item.thumb
                        }
                        alt="Pizza"
                        className="img-responsive img-curve"
                      />
                    </div>
                    <div className="text text-center">
                      <h4>
                        <Link to={"/products/" + item.id}>{item.title}</Link>
                      </h4>
                      <h5>
                        <Rating rating={item.rating} />
                        <span>({item.totalReviews})</span>
                      </h5>
                      <p>{item.description.slice(0, 50)}...</p>
                      <h5>$ {item.price}</h5>
                      <div className="flexSB">
                        <Link to={"/products/" + item.id} className="btn-primary">
                          <i className="fas fa-eye"></i> View Detail
                        </Link>
                        {item.active === "on" ? (
                          <Link
                            className="btn-primary"
                            onClick={() => {
                              addItemHandlar(item, item.id);
                            }}
                          >
                            <i className="fas fa-shopping-cart"></i> Add To Cart
                          </Link>
                        ) : (
                          <Link className="btn-primary disableLink">
                            <i className="fas fa-shopping-cart"></i> Stock Out
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </section>
    </>
  );
};

export default SingleProduct;
